import { getExecute } from '@/utils/dialogExecute'

export const rules = {
  companyName: [
    { required: true, message: '请输入制作公司', trigger: 'change' }
  ],
  contact: [
    { required: true, message: '请输入联系人', trigger: 'change' }
  ],
  mobile: [
    { required: true, message: '请输入联系人手机号', trigger: 'change' }
  ],
  certificate: [
    { required: true, message: '请上传拍摄许可证', trigger: 'change' }
  ],
  publicity: [
    { required: true, message: '请上传公示表文件', trigger: 'change' }
  ]
}

export const STATUS_MAP = [
  {
    label: '新增',
    key: 'NEW'
  },
  {
    label: '审核成功',
    key: 'SUCCESS'
  },
  {
    label: '审核失败',
    key: 'FAIL'
  }
]

export function resetSupportRecord() {
  return {
    companyName: '',
    contact: '',
    mobile: '',
    certificate: '',
    publicity: '',
  }
}

const singleVue = {
  addOrEditSupportRecord: false,
  showImg: false
}

const strategyMap = {
  addOrEditSupportRecord: () => import('./components/addOrEditSupportRecord.vue'), // 日志显示
  showImg: () => import('./components/showImg.vue') // 日志显示
}

export const execute = getExecute(singleVue, strategyMap)
